const fullPageLicenseKey = '251354C9-C6F7409F-B3112567-CA02C36E';
//const fullPageParallaxLicenseKey = '1DDA95F8-A510495C-BEC883D0-79C03035';
const fullPageParallaxLicenseKey = 'ZGFlbWFjb3JwLmNvLmtyX2JsNGNHRnlZV3hzWVhnPWZudA==';

$.scroll = {
    move : function(scrollTarget, scrollValue, speed, callback) {
        $(scrollTarget).animate({
            "scrollTop": isNaN(scrollValue) ? $(scrollValue).position().top : scrollValue
        }, speed, callback);
    }
};


$(function(){
    check_device();
    $('.common-header .btn-menu').click(function(){
        $('.common-all-nav').addClass('open');
    });
    $('.common-all-nav .btn-menu').click(function(){
        $(this).parent().removeClass('active');
        $('.common-all-nav').removeClass('open');
    });
    $('.sub-menu li a').click(function(){
        $('.common-all-nav').removeClass('open');
    });

    $('[data-button="btn-animate-move"] a').on("click", function (e) {
        e.preventDefault();
        $.scroll.move('html,body', $(this)[0].hash, 500);
    });

    if($('#sub-page').length > 0) {

        if($('.sub-contents').hasClass('has-sub-menu')) {
            $('.main-nav .active .sub-menu li').eq(0).find('> a').addClass('active');

            $(window).on('scroll', function() {
                $('.sub-contents .section').each(function() {
                    if($(window).scrollTop() >= ($(this).offset().top ) -1) {
                        var id = $(this).attr('id');
                        $('[data-button="btn-animate-move"] a').removeClass('active');
                        $('[data-button="btn-animate-move"] a[data-id="'+id+'"]').addClass('active');
                    }
                });
            });
        }

        $('#sub-page').addClass('loaded');
    }
});

function check_device() {
    /*
    var isMobile = false;
    var filter = "win16|win32|win64|mac";

    if (navigator.platform) {
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
    }
    */
    if(location.pathname.match("/m/") != null) {
        if(!checkMobileDevice()) {
            var temp = location.pathname;
            var pc_url = temp.replace(/\/m/gi, '');
            window.location.replace(pc_url+location.hash);
        }
    } else {
        if(checkMobileDevice()) {
            var temp = location.pathname;
            var mo_url = "/m"+temp;
            window.location.replace(mo_url+location.hash);
        }
    }
}

function checkMobileDevice() {
    var mobileKeyWords = new Array('Android', 'iPhone', 'iPod', 'BlackBerry', 'Windows CE', 'SAMSUNG', 'LG', 'MOT', 'SonyEricsson');
    for (var info in mobileKeyWords) {
        if (navigator.userAgent.match(mobileKeyWords[info]) != null) {
            return true;
        }
    }
    return false;
}